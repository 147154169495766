<template>
  <div id="query_use">
    <div class="users-management">
    <strong style="font-size:13px">用户管理</strong>
    <div class="buttons">
    <el-input
      v-model="state"
      placeholder="请输入需要搜索的姓名"
      @keyup.enter.native="$event.target.blur"
      @blur="querySearchAsync(state)"
      style="width:16vw;"
      clearable
    ><i slot="prefix" class="el-input__icon el-icon-search"></i></el-input>
      <el-button @click="querySearchAsync(state)" type="primary" style="line-height: 20px;" size="mini"  icon="el-icon-search">搜索
      </el-button>
      <el-button @click="refresh()" type="primary" style="line-height: 20px;" size="mini" >重置
      </el-button>
      <el-button @click="editAddUser()" type="primary" size="mini"  style="line-height: 20px;" v-if="level == '1' | level == '2'">新增员工
      </el-button>
    </div>
    </div>
     <AddUser
        :title="addUserTitle"
        :addUserVisible="addUserVisible"
        :units="units"
        @val-change="addUser"
        @cancel="addUserget"
        :close-on-click-modal = "false"
    ></AddUser>
    <div class="table-container">
      <el-table :data="tableData"  :row-class-name="tableRowClassName"  :row-style="{height: '0.1'}" :cell-style="{padding: '4px -15px'}">
        <el-table-column prop="name" label="姓名"  align="center"></el-table-column>
        <el-table-column prop="loginname" label="登录名"  align="center"></el-table-column>
        <el-table-column prop="unitname" label="单位"  align="center"></el-table-column>
        <el-table-column prop="department" label="部门"  align="center"></el-table-column>
        <el-table-column prop="rname" label="角色"  align="center"></el-table-column>
        <el-table-column prop="email" label="邮箱" width="160px" align="center"></el-table-column>
        <!-- <el-table-column prop="createdon" label="创建时间" width="100px"></el-table-column> -->
        <el-table-column prop="lastupdateon" label="最新操作时间" width="160px" align="center"></el-table-column>
        <!-- <el-table-column prop="password" label="密码" width="100px"></el-table-column> -->
        <el-table-column label="状态" width="80px" align="center">
          <template slot-scope="scope">
                <el-tag type="success" effect="dark"  v-if="scope.row.active=='启用'">启用</el-tag>
                <el-tag type="danger" effect="dark"  v-if="scope.row.active=='停用'">停用</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="220px"
          align="center">
          <template slot-scope="scope">
            <el-button-group>
              <el-button @click="showEditDialog(scope.row)" type="text" style="margin-right:10px" >信息编辑</el-button>
              <el-button @click="showpwDialog(scope.row)" type="text" style="margin-right:10px" >密码修改</el-button>
              <el-button @click.native.prevent="deleteRow(scope.row.employeeid, tableData)" type="text"  v-if="level == '1' | level == '2'">删除用户</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
   </div>
  <div style="text-align: right; padding: 30px">
      <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page.sync="currentpage"
      :page-size="pagesize"
      layout="prev, pager, next, jumper"
      :total="totalcount">
    </el-pagination>
  </div>
  <div>
  <el-dialog
      title="用户信息编辑"
      :visible.sync="editDialogVisible"
      width="800px"
      :before-close = "editDialogClosed"
      :close-on-click-modal = "false"
      >
       <el-form label-position="right" label-width="100px" :inline="true" :model="editForm"  ref="editFormRef"
                     style="padding: 0px 70px 0px 10px;" class="demo-ruleForm" :rules="rules">
          <el-form-item label="姓名">
            <el-input v-model="editForm.name"  placeholder="姓名" disabled></el-input>
          </el-form-item>
          <el-form-item label="登录名">
              <el-input v-model="editForm.loginname"  placeholder="账号登录名" disabled ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password" >
              <el-input type="password" v-model="editForm.password" disabled clearable placeholder="登录密码" show-password></el-input>
          </el-form-item>
          <el-form-item label="单位" prop="unitname">
              <el-input v-model="editForm.unitname" clearable placeholder="单位" disabled ></el-input>
          </el-form-item>
          <el-form-item label="部门" prop="department">
              <el-input v-model="editForm.department" clearable placeholder="部门"></el-input>
          </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model.number="editForm.email" clearable placeholder="邮箱"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
              <el-input v-model="editForm.phone" clearable placeholder="联系电话"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="active" v-if="level == '1' | level == '2'">
              <el-select v-model="editForm.active" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
          </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogClosed">取 消</el-button>
        <el-button type="primary" @click="editUserInfo(editForm)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
   <div>
  <el-dialog
      title="密码修改"
      :visible.sync="pwVisible"
      width="800px"
      :close-on-click-modal = "false"
      >
       <el-form label-position="right" label-width="100px" :inline="true" :model="pwtable"  ref="pwtableRef"
                     style="padding: 0px 70px 0px 10px;" class="demo-ruleForm" :rules="pwrules">
          <el-form-item label="姓名">
            <el-input v-model="editForm.name"  placeholder="姓名" disabled></el-input>
          </el-form-item>
          <el-form-item label="登录名">
              <el-input v-model="editForm.loginname"  placeholder="账号登录名" disabled ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password" >
              <el-input type="password" v-model="pwtable.password"  clearable placeholder="登录密码" show-password></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPassword" >
              <el-input type="password" v-model="pwtable.checkPassword"  clearable placeholder="登录密码" show-password></el-input>
          </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="pwClosed">取 消</el-button>
        <el-button type="primary" @click="pwUserInfo(pwtable)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</div>
</template>

<script>
import AddUser from './addusers.vue'
// import { Message } from 'element-ui'
import md5 from 'js-md5'
export default {
  name: 'query_unit',
  components: {
    AddUser
  },
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.pwtable.checkPassword !== '') {
          this.$refs.pwtableRef.validateField('checkPassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.pwtable.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    var checktel = (rule, value, callback) => {
      if (!value || value === '') {
        return callback(new Error('手机号码不能为空'))
      }
      if (!(/^1[3|4|5|7|8|9][0-9]\d{4,8}$/.test(value))) {
        callback(new Error('请输入合法的手机号码'))
      } else {
        callback()
      }
    }
    var emailcheck = (rule, value, callback) => {
      if (!value || value === '') {
        return callback(new Error('邮箱不能为空'))
      }
      if (!(/^\w+@\w+(\.\w+)+$/.test(value))) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        callback()
      }
    }
    return {
      // 默认显示第几页
      currentpage: 1,
      // 总条数
      totalcount: 20,
      // 默认每页显示的条数
      pagesize: 10,
      tableData: [],
      state: '',
      currentPage3: 1,
      dialogFormVisible: false,
      pwVisible: false,
      addUserVisible: false,
      addUserTitle: '新增用户',
      editDialogVisible: false,
      editForm: {
        name: '',
        loginname: '',
        unitname: '',
        department: '',
        email: '',
        phone: '',
        role: '',
        active: ''
      },
      pwtable: {},
      options: [{
        value: '启用',
        label: '启用'
      }, {
        value: '停用',
        label: '停用'
      }],
      pwrules: {
        password: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        checkPassword: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ]
      },
      rules: {
        department: [
          { required: true, message: '请输入部门' }
        ],
        phone: [
          { required: true, validator: checktel }
        ],
        role: [
          { required: true, message: '请输入角色' }
        ],
        email: [
          { required: true, validator: emailcheck }
        ]
      },
      roles: [{
        value: '1',
        label: '超级管理员'
      }, {
        value: '2',
        label: '管理员'
      }, {
        value: '3',
        label: '普通用户'
      }
      ],
      level: '',
      units: []
    }
  },
  created () {
    this.level = localStorage.getItem('role_id')
    this.getdatas(this.pagesize, this.currentpage - 1)
  },
  methods: {
    querySearchAsync (search) {
      console.log(search)
      this.axios.post('/users/usersearch/', {
        employeeid: localStorage.getItem('user_id'),
        unitid: localStorage.getItem('unit_id'),
        roleid: localStorage.getItem('role_id'),
        rolename: localStorage.getItem('role_name'),
        searchname: search
      }).then((res) => {
        this.tableData = res.data.data
        this.totalcount = ''
      }).catch((error) => {
        console.log(error)
      })
    },
    getdatas (n1, n2) {
      this.axios.post('/users/', {
        employeeid: localStorage.getItem('user_id'),
        unitid: localStorage.getItem('unit_id'),
        roleid: localStorage.getItem('role_id'),
        rolename: localStorage.getItem('role_name'),
        limit: n1,
        offset: n2
      }).then((res) => {
        this.tableData = res.data.data
        this.totalcount = res.data.numbers[0].numbers
      }).catch((error) => {
        console.log(error)
      })
    },
    showEditDialog (row) {
      this.editForm = row
      this.editDialogVisible = true
    },
    showpwDialog (row) {
      this.editForm = row
      this.pwVisible = true
    },
    addUserget () {
      this.addUserVisible = false
      this.getdatas(this.pagesize, this.currentpage - 1)
    },
    editDialogClosed () {
      this.getdatas(this.pagesize, this.currentpage - 1)
      this.editDialogVisible = false
    },
    pwClosed () {
      this.pwtable = {}
      this.$refs.pwtableRef.resetFields()
      this.pwVisible = false
    },
    editUserInfo (editForm) {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        this.axios
          .post('/users/updateuser/', {
            userloginname: this.editForm.loginname,
            useractive: this.editForm.active,
            userphone: this.editForm.phone,
            userdepartment: this.editForm.department,
            useremail: this.editForm.email,
            createdby: localStorage.getItem('yun_user')
          }).then((res) => {
            // 更新数据
            this.getdatas(this.pagesize, this.currentpage - 1)
            // 关闭对话框
            this.editDialogVisible = false
            // 提示修改成功
            this.$message.success('更新用户信息成功！')
          })
      })
    },
    pwUserInfo (editForm) {
      console.log('this.pwtable.password', this.pwtable.password)
      this.$refs.pwtableRef.validate(async valid => {
        if (!valid) return
        this.axios
          .post('/users/updatepw/', {
            userloginname: this.editForm.loginname,
            userpassword: md5(this.pwtable.password),
            createdby: localStorage.getItem('yun_user')
          }).then((res) => {
            if (res.data.res === true) {
              // 更新数据
              this.getdatas(this.pagesize, this.currentpage - 1)
              // 关闭对话框
              this.pwVisible = false
              // 提示修改成功
              this.$message.success(res.data.message)
              this.pwtable = {}
            } else if (res.data.res === false) {
              this.$message.warning(res.data.message)
            }
          })
      })
    },
    editAddUser () {
      this.addUserVisible = true
      this.getunitlist()
    },
    getunitlist () {
      this.axios.post('/users/userunitlist/', {
        unitid: localStorage.getItem('unit_id'),
        unituser: localStorage.getItem('role_name')
      }).then((res) => {
        this.units = res.data.data
      }).catch((error) => {
        console.log(error)
      })
    },
    addUser (data) {
    },
    refresh () {
      this.state = ''
      this.getdatas(this.pagesize, this.currentpage - 1)
    },
    deleteRow (index, rows) {
      console.log('index', index)
      this.$confirm('此操作将永久删除该账号, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios
          .post('/users/deleteuser/', {
            employeeid: index
          }).then((res) => {
            this.getdatas(this.pagesize, this.currentpage - 1)
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'success-row'
      }
    },
    // 显示第几页
    handleCurrentChange (val) {
      console.log('显示第几页', val, val * 10)
      // 改变第几页
      this.currentpage = val
      this.getdatas(this.pagesize, (val - 1) * 10)
    }
  }
}
</script>

<style scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table >>> .success-row {
  background: #f0f9eb;
}
.el-divider{
   margin: 1px;
}
.table-container {
  display: flex;
  flex-direction: column;
  height: 100;
  position: relative;
}

.users-management {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
}
.users-management strong {
  font-size: 2vw;
}
.buttons {
  display: flex;
  gap: 10px;
}
</style>
