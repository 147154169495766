<template>
    <div>
        <el-dialog :title="title" :visible.sync="addUserVisible"  width="800px"
                   :before-close="handleCancel" :close-on-click-modal="false" @open="openAddUser()">
            <el-form label-position="right" label-width="100px" :inline="true" :model="userInfo" :rules="rules" ref="user"
                     style="padding: 0px 70px 0px 10px;" class="demo-ruleForm">
                <el-form-item label="姓名" prop="name" style="margin-bottom:30px">
                    <el-input v-model="userInfo.name"  clearable placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="账号登录名" prop="loginname" style="margin-bottom:30px">
                    <el-input v-model="userInfo.loginname" clearable placeholder="请输入账号登录名"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password" style="margin-bottom:30px">
                    <el-input type="password" v-model="userInfo.password" placeholder="请输入登录密码"
                              autocomplete="off" clearable show-password></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPassword" style="margin-bottom:30px">
                    <el-input type="password" v-model="userInfo.checkPassword"
                              placeholder="请确认登录密码"
                              autocomplete="off" clearable show-password></el-input>
                </el-form-item>
                <el-form-item label="单位" prop="unitid" style="margin-bottom:30px">
                    <el-select v-model="userInfo.unitid" placeholder="请选择">
                    <el-option
                      v-for="item in units"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="部门" prop="department" style="margin-bottom:30px">
                    <el-input v-model="userInfo.department" clearable placeholder="请输入部门"></el-input>
                </el-form-item>
                <el-form-item label="角色" prop="roleid" style="margin-bottom:30px">
                  <el-select v-model="userInfo.roleid" placeholder="请选择">
                    <el-option
                      v-for="item in roles"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input v-model="userInfo.email" clearable placeholder="请输入邮箱"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="userInfo.phone" clearable placeholder="请输入手机号"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onSubmit('user')">创建用户</el-button>
                <el-button @click="resetForm('user')">重置信息</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import md5 from 'js-md5'
export default {
  name: 'AddUser',
  props: {
    addUserVisible: Boolean,
    data: [Object, Boolean],
    title: String,
    units: [Array, Object]
  },
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } if (!(/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,}$/.test(value))) {
        callback(new Error('密码必须包含大写字母、数字、特殊字符且至少8位!'))
      } if ((value.toLowerCase()).search(this.userInfo.loginname.toLowerCase()) > -1) {
        callback(new Error('密码禁止与登录账号相同或包含登录账号'))
      } else {
        console.log('11111', value.toLowerCase)
        if (this.userInfo.checkPassword !== '') {
          this.$refs.user.validateField('checkPassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.userInfo.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    var checktel = (rule, value, callback) => {
      if (!value || value === '') {
        return callback(new Error('手机号码不能为空'))
      }
      if (!(/^1[3|4|5|7|8|9][0-9]\d{4,8}$/.test(value))) {
        callback(new Error('请输入合法的手机号码'))
      } else {
        callback()
      }
    }
    var emailcheck = (rule, value, callback) => {
      if (!value || value === '') {
        return callback(new Error('邮箱不能为空'))
      }
      if (!(/^\w+@\w+(\.\w+)+$/.test(value))) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        callback()
      }
    }
    var checkloginname = (rule, value, callback) => {
      if (!value || value === '') {
        return callback(new Error('请输入账号登录名'))
      } else {
        this.axios
          .post('/users/checkname/', {
            loginname: value
          }).then((res) => {
            console.log('resss', res.data.res)
            if (res.data.res === false) {
              callback(new Error('账号登录名重复，不可用！'))
            } else {
              callback()
            }
          })
      }
    }
    return {
      userInfo: {
        name: '',
        loginname: '',
        department: '',
        phone: '',
        roleid: '',
        unitid: '',
        email: '',
        password: '',
        checkPassword: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        checkPassword: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ],
        loginname: [
          { required: true, validator: checkloginname, trigger: 'blur' }
        ],
        identity: [
          { required: true, trigger: 'blur' }
        ],
        department: [
          { required: true, message: '请输入部门', trigger: 'blur' }
        ],
        unitid: [
          { required: true, message: '请输入单位', trigger: 'blur' }
        ],
        phone: [
          { required: true, validator: checktel, trigger: 'blur' }
        ],
        roleid: [
          { required: true, message: '请输入角色', trigger: 'blur' }
        ],
        email: [
          { required: true, validator: emailcheck, trigger: 'blur' }
        ]
      },
      roles: [{
        value: '1',
        label: '超级管理员'
      }, {
        value: '2',
        label: '管理员'
      }, {
        value: '3',
        label: '普通用户'
      }
      ]
    }
  },
  methods: {
    openAddUser () {
      this.$nextTick(function () {
        this.$refs.user.resetFields()
        for (var key in this.entity) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.entity.hasOwnProperty(key)) {
            this.entity[key] = ''
          }
        }
      })
    },
    // 关闭弹窗
    handleCancel () {
      this.$refs.user.resetFields()
      this.$emit('cancel')
    },
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios
            .post('/users/insertuser/', {
              username: this.userInfo.name,
              userloginname: this.userInfo.loginname,
              userpassword: md5(this.userInfo.password),
              userunitid: this.userInfo.unitid,
              userroleid: this.userInfo.roleid,
              userphone: this.userInfo.phone,
              userdepartment: this.userInfo.department,
              useremail: this.userInfo.email,
              createdby: localStorage.getItem('yun_user')
            }).then((res) => {
              if (res.data.res === true) {
                this.$message.warning('创建成功！')
                this.$emit('cancel')
              }
            })
          this.resetForm()
        } else {
          this.$message.warning('校验未通过！')
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style scoped>

</style>
